<template>
  <div class="setting">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Article',
};
</script>

<style></style>